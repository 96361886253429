import {useEffect, useState} from 'react';

export default function useExternalScripts({url}) {
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(false);

    useEffect(() => {
        const head = document.querySelector("head");
        const script = document.createElement("script");

        script.setAttribute("src", url);
        script.async = true;

        script.onload = () => {
            setLoaded(true);
            console.log(`${url} loaded successfully`);
        };

        script.onerror = () => {
            setError(true);
            console.error(`Error loading script: ${url}`);
        };

        head.appendChild(script);

        return () => {
            head.removeChild(script);
        };
    }, [url]);

    return {loaded, error};
}
