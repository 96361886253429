import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'
import {
    PayPalButtons,
    PayPalCardFieldsProvider,
    PayPalCVVField,
    PayPalExpiryField,
    PayPalNameField,
    PayPalNumberField,
    usePayPalCardFields,
    usePayPalScriptReducer,
} from "@paypal/react-paypal-js";
import useExternalScripts from "../../utils/useExternalScripts";
import {useMediaQuery} from "react-responsive";
import GooglePayButton from "@google-pay/button-react";
import {isInDebugMode} from "../../utils/commonFunctions";

// PayPal
const currency = "EUR";

const standardCardFieldStyle = {
    input: {
        'border-radius': '28px',
        'height': '55px',
        'min-height': '40px',
        'max-height': '55px',
    }
}


const ApplePayButton = ({onClick, id}) => {

    const componentId = `btn-appl-button-${id}`;

    useEffect(
        () => {
            const btn = document.getElementById(componentId);
            btn.addEventListener("click", onClick);
            return () => {
                if (btn) {
                    btn.removeEventListener("click", onClick);
                }
            };

        }, [onClick]
    );

    return (
        <apple-pay-button
            id={componentId}
            buttonstyle="black"
            type="buy"
            locale="it"
        ></apple-pay-button>
    );
};

const SubmitPayment = ({isPaying, setIsPaying}) => {
    const {cardFieldsForm, fields} = usePayPalCardFields();

    const handleClick = async () => {
        if (!cardFieldsForm) {
            const childErrorMessage =
                "Unable to find any child components in the <PayPalCardFieldsProvider />";

            throw new Error(childErrorMessage);
        }
        const formState = await cardFieldsForm.getState();

        if (!formState.isFormValid) {
            return alert("The payment form is invalid");
        }
        setIsPaying(true);

        cardFieldsForm.submit().catch((err) => {
            setIsPaying(false);
        });
    };

    return (
        <Button
            variant="contained"
            color="primary"
            disabled={isPaying}
            onClick={handleClick}
        >
            {isPaying ? <CircularProgress/> : "Paga"}
        </Button>
    );
};

export const AdvancedPayment = (props) => {
    const isMobile = useMediaQuery({maxWidth: 600});
    const {loaded: applePayLoaded} = useExternalScripts({
        url: "https://applepay.cdn-apple.com/jsapi/v1/apple-pay-sdk.js",
    });
    const [isPaying, setIsPaying] = useState(false);
    const [showCardFields, setShowCardFields] = useState(false);
    const [allowedPaymentMethods, setAllowedPaymentMethods] = useState(null)
    const [showApplePayButton, setShowApplePayButton] = useState(false)
    const [applePayConfig, setApplePayConfig] = useState(null)
    const [merchantInfo, setMerchantInfo] = useState(null)
    const [{isResolved}] = usePayPalScriptReducer();
    const {
        onMarkAsPaymentError,
        paypalAnswered,
        onPaypalAnswered,
        onCreateOrder,
        onCaptureOrder,
        onCompleteOrder,
        amount,
        practiceId,
        canPayWithGoogleAndApplePay
    } = props


    useEffect(() => {
        if (isResolved && canPayWithGoogleAndApplePay) {
            configGPay();

            if (applePayLoaded) {
                configAPay();
            }
        }
    }, [isResolved, applePayLoaded]);

    const handleShowCardFields = () => {
        setShowCardFields(true);
    }

    const handleApplePay = () => {
        console.info("Apple Pay Button Clicked");
        //Controllo nuovamente che ApplePay sia supportato
        if (!window.ApplePaySession || !window.ApplePaySession.canMakePayments()) {
            console.error("Apple Pay non è supportato su questo dispositivo.");
            onError("Apple Pay non è supportato su questo dispositivo.");
            return;
        }
        const paymentRequest = {
            countryCode: applePayConfig.countryCode,
            currencyCode: currency,
            supportedNetworks: applePayConfig.supportedNetworks,
            merchantCapabilities: applePayConfig.merchantCapabilities,
            requiredShippingContactFields: ['name', 'phone', 'email', 'postalAddress'],
            requiredBillingContactFields: ['postalAddress'],
            total: {
                label: "Totale",
                type: 'final',
                amount: amount,
            },
        };

        const APPLE_PAY_VERSION = 4; //Indica la versione di Apple Pay che il sito supporta. Doc: https://developer.apple.com/documentation/apple_pay_on_the_web/apple_pay_on_the_web_version_history
        const session = new window.ApplePaySession(APPLE_PAY_VERSION, paymentRequest);
        const applepay = window.paypal.Applepay();

        session.onvalidatemerchant = (event) => {
            applepay.validateMerchant({
                validationUrl: event.validationURL
            }).then(validateResult => {
                session.completeMerchantValidation(validateResult.merchantSession);
            }).catch(validateError => {
                console.error(validateError);
                session.abort();
                onError(validateError);
            });
        };

        session.onpaymentauthorized = async (event) => {
            try {
                const orderID = await onCreateOrder(amount);
                console.info("Ordine creato con successo:", orderID);

                const confirmResult = await applepay.confirmOrder({
                    orderId: orderID,
                    token: event.payment.token,
                    billingContact: event.payment.billingContact,
                });

                console.info(confirmResult);
                const status = confirmResult.approveApplePayPayment?.status;

                if (status === "APPROVED" || status == "APPROVED") {
                    session.completePayment(window.ApplePaySession.STATUS_SUCCESS);
                    const captureResult = await onCaptureOrder(orderID);
                    console.info("Pagamento catturato con successo:", captureResult);
                    await onCompleteOrder(orderID);
                } else {
                    console.error("Errore durante la conferma dell'ordine. ConfirmResultData APay: " + JSON.stringify(confirmResult));
                    session.completePayment(window.ApplePaySession.STATUS_FAILURE);
                    onError("Errore durante la conferma dell'ordine.");
                }
            } catch (error) {
                console.error("Errore nel processo di pagamento Apple Pay:", error);
                session.completePayment(window.ApplePaySession.STATUS_FAILURE);
                onError(error);
            }
        };
        session.begin();
    };


    const configGPay = async () => {
        const googlePayConfig = await window.paypal.Googlepay().config();
        console.info('GooglePay Config:', googlePayConfig);
        setAllowedPaymentMethods(googlePayConfig.allowedPaymentMethods);
        setMerchantInfo(googlePayConfig.merchantInfo);
    }

    const configAPay = async () => {
        if (!window.ApplePaySession || !window.ApplePaySession.canMakePayments) {
            console.error('Il dispositivo non supporta Apple Pay');
        }
        const applepay = window.paypal.Applepay();
        applepay.config()
            .then(applepayConfig => {
                console.info('ApplePay Config:', applepayConfig);
                if (applepayConfig.isEligible) {
                    setShowApplePayButton(true);
                    setApplePayConfig(applepayConfig);
                } else {
                    setShowApplePayButton(false);
                    console.error('Apple Pay non è abilitato');
                }
            })
            .catch(applepayConfigError => {
                console.error('Errore durante il fetching di Apple Pay. ->' + applepayConfigError);
            });

    }


    function onApprove(data) {
        console.info('Order Approved:', data.orderID);
        return onCaptureOrder(data.orderID).then(async (details) => {
            console.info('Payment Successful:', details);
            onPaypalAnswered(true);
            await onCompleteOrder(data.orderID);
        }).catch((error) => {
            console.error("Error capturing order:", error);
            onError(error);
        });
    }

    function onCreate() {
        return onCreateOrder(amount).then((orderID) => {
            console.info('Order Created:', orderID);
            return orderID;
        });
    }

    function onError(err) {
        console.log({onError: err})
        onPaypalAnswered(true)
        onMarkAsPaymentError(err)
    }

    return (
        <Grid container spacing={3} className="panel">
            {(
                <>
                    <Grid item xs={12} sm={1}></Grid>
                    <Grid
                        item
                        xs={12}
                        sm={10}
                        className="payment-wrapper"
                        align-items="center">
                        <Paper variant="outlined"
                               style={{
                                   width: "100%",
                                   ...(isMobile
                                       ? {}
                                       : {minWidth: "300px", padding: "20px"}),
                                   maxWidth: "750px",
                                   margin: "0 auto",
                               }}
                        >
                            {showApplePayButton && canPayWithGoogleAndApplePay && <ApplePayButton onClick={handleApplePay} id={practiceId}/>}
                            {showApplePayButton && canPayWithGoogleAndApplePay && <br/>}
                            {merchantInfo && allowedPaymentMethods && canPayWithGoogleAndApplePay &&  <GooglePayButton
                                onCancel={() => {
                                    onError('Pagamento rifiutato. Attendi che la pagina venga ricaricata. Se non dovesse succedere, ricarica manualmente.');
                                    setTimeout(() => {
                                        window.location.reload();
                                    }, 2000);
                                }}
                                onError={err => onError(err)}
                                style={{
                                    width: "100%",
                                    ...(isMobile
                                        ? {}
                                        : {minWidth: "300px"}),
                                    maxWidth: "750px",
                                    height: "55px",
                                    minHeight: "40px",
                                    maxHeight: "55px",
                                    marginBottom: "17px"
                                }}
                                onPaymentAuthorized={async paymentResponse => {
                                    console.info('Payment Authorised:', paymentResponse);
                                    const id = await onCreateOrder(amount);
                                    const confirmOrderResponse = await window.paypal.Googlepay().confirmOrder({
                                        orderId: id,
                                        paymentMethodData: paymentResponse.paymentMethodData,
                                    })
                                    if (confirmOrderResponse.status === "PAYER_ACTION_REQUIRED") {
                                        console.log("==== Confirm Payment Completed Payer Action Required =====");
                                        try {
                                            await window.paypal.Googlepay().initiatePayerAction({orderId: id});
                                            console.log("===== Payer Action Completed =====");
                                        } catch (error) {
                                            console.error("Error initiating payer action:", error);
                                            onError('Errore durante la conferma dell\'ordine');
                                            return {
                                                transactionState: "ERROR",
                                                error: {message: "Errore durante l'inizializzazione dell'azione del pagante"},
                                            };
                                        }

                                        await onCaptureOrder(id);
                                        onPaypalAnswered(true);
                                        await onCompleteOrder(id);
                                        return {transactionState: "SUCCESS"};

                                    } else if (confirmOrderResponse.status === "APPROVED") {
                                        const captureResponse = await onCaptureOrder(id);
                                        console.log({captureResponse});
                                        // onPaypalAnswered(true);
                                        await onCompleteOrder(id);
                                        return {transactionState: "SUCCESS"};
                                    } else {
                                        onError("Errore durante la conferma dell'ordine");
                                        console.error("Errore durante la conferma dell'ordine. ConfirmOrderResponse GPay = " + JSON.stringify(confirmOrderResponse));
                                        return {
                                            transactionState: "ERROR",
                                            error: {message: "Errore durante la conferma dell'ordine"}
                                        };
                                    }
                                }}
                                buttonRadius={28}
                                environment={isInDebugMode ? "TEST" : "PRODUCTION"}
                                buttonLocale={"it"}
                                buttonSizeMode={"fill"}
                                onReadyToPayChange={result => {
                                    console.info('Ready to Pay:', result);
                                }}
                                paymentRequest={{
                                    apiVersion: 2,
                                    apiVersionMinor: 0,
                                    allowedPaymentMethods: allowedPaymentMethods,
                                    merchantInfo: merchantInfo,
                                    transactionInfo: {
                                        totalPriceStatus: 'FINAL',
                                        totalPriceLabel: 'Totale',
                                        totalPrice: amount,
                                        currencyCode: currency,
                                    },
                                    callbackIntents: ['PAYMENT_AUTHORIZATION'],
                                }}
                                onLoadPaymentData={paymentData => {
                                    console.log('load payment data', paymentData);
                                }}
                            />}
                            <PayPalButtons
                                createOrder={(_) => onCreate()}
                                onApprove={(data) => onApprove(data)}
                                onError={onError}
                                onCancel={data => console.log({onCancel: data})}
                                style={{
                                    shape: "pill",
                                    layout: "vertical",
                                    color: "gold",
                                    label: "paypal"
                                }}
                            />


                            {!showCardFields && <Button
                                variant="contained"
                                onClick={handleShowCardFields}
                                style={{
                                    width: "100%",
                                    ...(isMobile
                                        ? {}
                                        : {minWidth: "300px"}),
                                    maxWidth: "750px",
                                    height: "55px",
                                    minHeight: "40px",
                                    maxHeight: "55px",
                                    marginTop: "10px",
                                    borderRadius: "28px",
                                    backgroundColor: "black",
                                    color: "white",
                                    textTransform: 'capitalize',
                                }}
                            >
                                Acquista con Carta
                            </Button>}

                            {showCardFields && <PayPalCardFieldsProvider
                                onError={onError}
                                createOrder={() => onCreate()}
                                onApprove={(data) => onApprove(data)}
                                onCancel={data => console.log({onCancel: data})}
                            >
                                <PayPalNameField style={standardCardFieldStyle}/>
                                <PayPalNumberField style={standardCardFieldStyle}/>
                                <PayPalExpiryField style={standardCardFieldStyle}/>
                                <PayPalCVVField style={standardCardFieldStyle}/>
                                <SubmitPayment
                                    isPaying={isPaying}
                                    setIsPaying={setIsPaying}
                                />
                            </PayPalCardFieldsProvider>}
                            {paypalAnswered && <CircularProgress/>}
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={1}></Grid>
                </>
            )}
        </Grid>
    )
}

AdvancedPayment.propTypes = {
    amount: PropTypes.string.isRequired,
    onCreateOrder: PropTypes.func.isRequired,
    onCaptureOrder: PropTypes.func.isRequired,
    onMarkAsPaymentError: PropTypes.func.isRequired,
    onPaypalAnswered: PropTypes.func.isRequired,
    paypalAnswered: PropTypes.bool.isRequired,
    onCompleteOrder: PropTypes.func.isRequired,
    practiceId: PropTypes.string.isRequired,
}
