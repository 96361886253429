import React, {Component} from 'react';

import PracticeItem from '../components/PracticeItem';
import ParticipantDetails from '../components/ParticipantDetails';
import {Container, EmptyText, ErrorAlert, Spinner,} from '../utils/commonStyledComponents';
import {fetchPractices} from '../utils/api';
import YearItem from "../components/YearItem";
import {filterPracticesByYear, getPracticeYears} from "../utils/commonFunctions";
import UpsellingPackageDetails from "../components/UpsellingPackageDetails";

class UpsellingPractices extends Component {
    state = {
        loading: true,
        modalOpen: false,
        practices: [],
        showErrorMessage: false,
        selectedPractice: null,
    };

    componentDidMount() {
        this.getPractices();
    }

    getPractices = async () => {
        this.setState({loading: true});

        try {
            const data = await fetchPractices();

            this.setState({practices: data, loading: false});
        } catch (e) {
            this.setState({showErrorMessage: true, loading: false});
        }
    };

    render() {
        const {practices} = this.state;

        if (this.state.showErrorMessage) {
            return <ErrorAlert>Si è verificato un errore di caricamento</ErrorAlert>;
        }

        if (this.state.loading) {
            return <Spinner/>;
        }

        if (practices.length === 0) {
            return <EmptyText>Nessuna pratica disponibile</EmptyText>;
        }

        const years = getPracticeYears(practices);
        return (
            <Container>
                {years.map(y => {
                    const filteredPractices = filterPracticesByYear(practices, y);
                    return <YearItem key={y} year={y}>
                        {filteredPractices.map(practice =>
                            (
                                <PracticeItem key={practice.id} practice={practice}>
                                    {practice.participants?.map(p => <ParticipantDetails
                                        practiceId={practice.id}
                                        editable={false}
                                        {...p}
                                    />)}
                                    <UpsellingPackageDetails {...practice} />
                                </PracticeItem>
                            ))}
                    </YearItem>
                })}
            </Container>
        );
    }
}

export default UpsellingPractices;
