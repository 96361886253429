let config;

if (process.env.REACT_APP_MEMBERSHIP_ENV === 'production') {
  config = {
    apiUrl: 'https://gestionale.giocamondo.it/api',
    shopUrl: 'https://shop.giocamondo.it',
    signServer: 'https://modulob.giocamondo.it/',
    yousignSandbox: false,
  };
} else if (process.env.REACT_APP_MEMBERSHIP_ENV === 'staging') {
  config = {
    apiUrl: 'https://staging.giocamondo.it/api',
    shopUrl: 'https://shop.staging.giocamondo.it',
    signServer: 'https://staging.modulob.giocamondo.it/',
    yousignSandbox: true,
  };
} else if (process.env.REACT_APP_MEMBERSHIP_ENV === 'staging-bis') {
  config = {
    apiUrl: 'https://staging-bis.giocamondo.it/api',
    shopUrl: 'https://shop-bis.staging.giocamondo.it',
    yousignSandbox: true,
  };
} else {
  config = {
    /* apiUrl: 'http://localhost:8080/index.php/api',
    shopUrl: 'http://localhost:8080/shop',
    signServer: 'http://localhost:8081/', */
    apiUrl: 'https://staging.giocamondo.it/api',
    shopUrl: 'https://shop.staging.giocamondo.it',
    signServer: 'https://staging.modulob.giocamondo.it/',
    yousignSandbox: true,
  };
}

export default config;
