import React, {Component} from 'react';
import styled from 'styled-components';

import PracticeItem from '../components/PracticeItem';
import ParticipantDetails from '../components/ParticipantDetails';
import PackageDetails from '../components/PackageDetails';
import Button from '../components/Button';
import ModifyPractices from '../components/ModifyPractices';
import {Container, EmptyText, ErrorAlert, Spinner,} from '../utils/commonStyledComponents';
import Alert from '@material-ui/lab/Alert';
import {
    fetchPractices,
    getParams,
    MAP_PRODUCT_DIVISION_FORM,
    PRODUCT_DIVISIONS_LIST,
    updatePaymentData
} from '../utils/api';
import {PRACTICE_STATUS_CONFERMATO, PRACTICE_STATUS_OPZIONATO,} from '../utils/constants';
import YearItem from "../components/YearItem";
import {filterPracticesByYear, getPracticeYears} from "../utils/commonFunctions";
import StatusAlert from "../components/StatusAlert";

class Practices extends Component {
    state = {
        loading: true,
        modalOpen: false,
        practices: [],
        showErrorMessage: false,
        selectedPractice: null,
        partoSicuroFlag: false,
        partoSicuroPracticesMap: {},
        statusAlert: null,
        isGetFlagPartoSicuroMapDone: false,
    };

    componentDidMount() {
        this.getPractices();
        this.getFlagPartoSicuroMap();
    }

    onCompletePayment = async (practice) => {
        try {
            await updatePaymentData(practice.practiceGUID, 'inps', 'sicuro');
            this.displayAlert('Pagamento completato con successo. Attendi che la pagina venga ricaricata', 'success');
            setTimeout(() => {
                window.location.reload();
            }, 3000);
        } catch (e) {
            this.displayAlert('Si è verificato un errore durante il pagamento', 'error');
        }
    }

    displayAlert = (text, severity, duration) => {
        this.setState({
            statusAlert: {
                text,
                severity,
            },
        });

        setTimeout(() => {
            this.setState({statusAlert: null});
        }, duration || 4000);
    };

    getFlagPartoSicuroMap = async () => {
        let tempPartoSicuroPracticesMap = {...this.state.partoSicuroPracticesMap};
        try {
            this.setState({isGetFlagPartoSicuroMapDone: false});
            const productDivisions = Object.keys(PRODUCT_DIVISIONS_LIST);
            const promises = productDivisions.map(productDivisionId =>
                getParams(productDivisionId, ["areaClub.domandaPartoSicuro"])
            );
            const responses = await Promise.all(promises);
            responses.forEach((response, index) => {
                if (response['areaClub.domandaPartoSicuro']) {
                    const productDivisionId = productDivisions[index];
                    tempPartoSicuroPracticesMap[productDivisionId] = response['areaClub.domandaPartoSicuro'].status;
                }
            });
        } catch (e) {
        } finally {
            this.setState({partoSicuroPracticesMap: tempPartoSicuroPracticesMap, isGetFlagPartoSicuroMapDone: true});
        }
    }

    getPractices = async () => {
        this.setState({loading: true});

        try {
            const data = await fetchPractices();

            this.setState({practices: data, loading: false});
        } catch (e) {
            this.setState({showErrorMessage: true, loading: false});
        }
    };

    setModifying = id => {
        const {practices} = this.state;
        const selectedIndex = practices.findIndex(practice => practice.id === id);
        const updatedPractices = [...practices];
        updatedPractices[selectedIndex] = {
            ...this.state.selectedPractice,
            isModifying: true,
        };
        this.setState({practices: updatedPractices});
    };

    render() {
        const {practices, selectedPractice, statusAlert, isGetFlagPartoSicuroMapDone} = this.state;

        if (this.state.showErrorMessage) {
            return <ErrorAlert>Si è verificato un errore di caricamento</ErrorAlert>;
        }

        if (this.state.loading || !isGetFlagPartoSicuroMapDone) {
            return <Spinner/>;
        }

        if (practices.length === 0) {
            return <EmptyText>Nessuna prenotazione disponibile</EmptyText>;
        }

        if (statusAlert) {
            return <StatusAlert visible={!!statusAlert} severity={statusAlert?.severity}>
                {statusAlert?.text}
            </StatusAlert>
        }

        const years = getPracticeYears(practices);
        return (
            <Container>
                {years.map(y => {
                    const filteredPractices = filterPracticesByYear(practices, y);
                    return <YearItem key={y} year={y}>
                        {filteredPractices.map(practice => {
                            const participants = practice.participants;
                            const packageProps = {
                                ...practice.buyedPackage,
                                date: practice.creationDate,
                                destination: practice.destination,
                                list: practice.serviceSnapshots,
                                practicePrice: practice.totalCost,
                                productDivisionId: practice.practiceProductDivisionId,
                                partoSicuroPracticesMap: this.state.partoSicuroPracticesMap,
                                divisionId: practice.practiceDivisionId,
                                practiceId: practice.id,
                                editableInClubArea: practice.editableInClubArea,
                                practiceType: practice.practiceType,
                                partoSicuroFlag: this.state.partoSicuroFlag,
                                displayAlert: this.displayAlert,
                                practice: practice,
                                onCompletePayment: this.onCompletePayment,
                            };

                            const editable =
                                practice.readableStatus === PRACTICE_STATUS_OPZIONATO ||
                                practice.readableStatus === PRACTICE_STATUS_CONFERMATO;

                            return (
                                <PracticeItem key={practice.id} practice={practice}>
                                    {participants.map(p => <ParticipantDetails
                                        practiceId={practice.id}
                                        editable={editable}
                                        {...p}
                                    />)}

                                    <PackageDetails {...packageProps} />

                                    {practice.isModifying && (
                                        <>
                                            <AlertContainer>
                                                <ModifyingAlert severity="info">
                                                    Stai modificando la tua Prenotazione! Aggiorna la pagina quando
                                                    avrai ultimato le operazioni di modifica.
                                                </ModifyingAlert>

                                                <Button
                                                    size="small"
                                                    variant="outlined"
                                                    onClick={this.getPractices}
                                                    text="Aggiorna"
                                                />
                                            </AlertContainer>
                                        </>
                                    )}
                                </PracticeItem>
                            );
                        })}
                    </YearItem>
                })}


                <ModifyPractices
                    open={this.state.modalOpen}
                    practice={selectedPractice || {}}
                    close={() => this.setState({modalOpen: false})}
                    setStatusPending={this.setModifying}
                />
            </Container>
        );
    }
}

export default Practices;

const ArrowChar = styled.span`
  font-size: 24;
  padding-bottom: 3px;
`;

const AlertContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ModifyingAlert = styled(Alert)`
  display: inline-flex;
  margin: 16px;
`;
